import React from 'react'
import PropTypes from "prop-types"

const CountriesTable = (props) => {
  const {
      countriesList
  } = props
  const COLUMN_HEADER_LENGTH = countriesList.length
  const COLUMN_ROWS = []

  const buildColumnValues = (columnValues, columnIndex) => {
      columnValues?.map((value, index) => {
          if (!COLUMN_ROWS[index]) {
            COLUMN_ROWS[index] = Array(COLUMN_HEADER_LENGTH).fill('')
          }
          COLUMN_ROWS[index][columnIndex] = value
          return value
      })
  }
  const renderColumnHeaders = () => {
    return countriesList.map((country, index) => {
        buildColumnValues(country.columnValues, index)
        return(
            <th key={index}>{country.columnHeader}</th>
        )
    })
  }

  const renderColumnValues = () => {
      return COLUMN_ROWS?.map((rows, rowIndex) => {
          return (
              <tr key={rowIndex}>
                  {rows.map((cellValue, cellIndex) => {
                      return (
                          <td key={cellIndex}>{cellValue}</td>
                      )
                  })}
              </tr>
      )})
  }
    return (
        <table className="table is-bordered mx-auto">
            <thead>
                <tr>
                    { renderColumnHeaders() }
                </tr>
            </thead>
            <tbody>
                { renderColumnValues() }
            </tbody>
        </table>
    )
}

CountriesTable.propTypes = {
    countriesList: PropTypes.arrayOf(PropTypes.shape({
        columnHeader: PropTypes.string,
        columnSubHeader: PropTypes.string,
        columnValues: PropTypes.arrayOf(PropTypes.string)
    })),
}

export default CountriesTable