import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import ClientsSection from "../components/ClientsSection";
import CountriesTable from "../components/CountriesTable";
import CTAButton from "../components/CTAButton";
import CenteredBlurbsSection from "../components/CenteredBlurbsSection";
import SimpleHeader from "../components/SimpleHeader";
import HashtagHidden from "../components/HashtagHidden";


// eslint-disable-next-line
export const GlobalMarketCompensationDataPageTemplate = ({ intro, clients, closing, countries }) => {
  return (
    <>
      <section className="section is-small no-padding-bottom">
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Global Market Compensation Data - Kamsa</title>
            <meta property="og:title" content="Global Market Compensation Data - Kamsa" />
            <meta name="description" content="Kamsa is a compensation management system that combines global salary data & expert support from compensation consultants for simplified compensation planning." />
          </Helmet>
        </div>
      </section>
      {/* INTRO START */}
       <section id="intro" className="section section--gradient is-small">
        <div className="container">
          <div className="columns is-mobile is-multiline">
            <SimpleHeader
              heading={intro.heading}
              header={intro.header}
              subheader={intro.description}
              isPageHeader
              columnSize={'is-7'}
            />

            <div className="column is-12">
              <div className="columns is-multiline is-mobile has-text-centered">
                { intro.blurbs.map((value, index) => {
                  return (
                    <div key={index} className="column is-6">
                      <div className="block">
                        <div className="blurb-icon">
                          <div className="blurb-container no-mobile-padding">
                            <img className="img-125" src={value.image.publicURL || ''} alt={value.title} />
                          </div>
                        </div>
                      </div>
                      <div className="block">
                        <h3 className="subtitle is-h3">{value.title}</h3>
                        <p className="is-h3">{value.text}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="column is-12">
                <div className="has-text-centered">
                    <CTAButton link={intro.buttonLink} text={intro.buttonText} />
                </div>
            </div>

          </div>
        </div>
      </section>
      {/* INTRO END */}
      {/* COUNTRIES START */}
      <section id="countries" className="section section--gray is-small">
        <div className="container countries-table">
          <div className="box">
              <div className="block has-text-centered mb-6 mt-6">
                  <h2 className="subtitle has-text-weight-bold">{ countries.heading }</h2>
                  <ul>
                    { countries.bullets.map(({ text }, index) => <li key={index}><p className="is-size-4">{ text }</p></li>) }
                  </ul>
              </div>
          </div>
          <div className="block">
              <CountriesTable countriesList={countries.countriesList} />
          </div>
        </div>
      </section>
      {/* COUNTRIES END */}
      {/* CLOSING START */}
      <section id="closing" className="section section--gradient is-small">
          <CenteredBlurbsSection
              heading={closing.heading}
              content={closing.content}
              buttonLink={closing.buttonLink}
              buttonText={closing.buttonText}
          />
      </section>
      {/* CLOSING END */}
      {/* CLIENTS START */}
      <section id="clients" className="section section--gray is-medium no-padding-bottom">
        <ClientsSection clients={clients} />
      </section>
      {/* CLIENTS END */}
    </>
  );
};

GlobalMarketCompensationDataPageTemplate.propTypes = {
  intro: PropTypes.shape({
    heading: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    blurbs: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          title: PropTypes.string,
          text: PropTypes.string,
        })
    )
  }),
  clients: PropTypes.shape({
    title: PropTypes.string,
    client: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
    )
  }),
  closing: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.array,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
  }),
  countries: PropTypes.shape({
    heading: PropTypes.string,
    bullets: PropTypes.array,
    countriesList: PropTypes.arrayOf(
        PropTypes.shape({
          columnHeader: PropTypes.string,
          columnValues: PropTypes.arrayOf(PropTypes.string)
        })
    )
  })
};

const GlobalMarketCompensationDataPage = ({ pageContext, data }) => {
  const { frontmatter } = data.markdownRemark;
  const { clients } = pageContext
  clients.title = frontmatter.gmcdPageClients.title

  return (
    <Layout>
      <GlobalMarketCompensationDataPageTemplate
        intro={frontmatter.gmcdPageIntro}
        clients={clients}
        closing={frontmatter.gmcdPageClosing}
        countries={frontmatter.gmcdPageCountries}
      />
        <HashtagHidden hashtags={frontmatter.gcmdPageHashtags} />
    </Layout>
  );
};

GlobalMarketCompensationDataPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default GlobalMarketCompensationDataPage;

export const GlobalMarketCompensationDataQuery = graphql`
  query GlobalMarketCompensationDataPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        gmcdPageIntro {
          heading
          header
          description
          buttonLink
          buttonText
          blurbs {
            image {
              publicURL
            }
            title
            text
          }
        }
        gmcdPageClients {
          title
        }
        gmcdPageClosing {
          heading
          content {
            image {
                publicURL
            }
            text
          }
          buttonLink
          buttonText
        }
        gmcdPageCountries {
          heading
          bullets {
            text
          }
          countriesList {
            columnHeader
            columnSubHeader
            columnValues
          }
        }
        gcmdPageHashtags {
          title
        }
      }
    }
  }
`;
